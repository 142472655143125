export const mQuestionValues = {    
    0:0, 30: 50, 40: 53, 50: 56.1, 60: 58, 70: 61, 80: 63.8, 90: 66, 100: 68.7,
    110: 72, 120: 76, 130: 80, 566: 120
};

// Mevcut anahtarları sırala
const keys = Object.keys(mQuestionValues).map(Number).sort((a, b) => a - b);

// Mevcut anahtarlar arasında eksik olanları hesaplayıp güncelle
for (let i = 0; i < keys.length - 1; i++) {
    let startX = keys[i];
    let endX = keys[i + 1];
    let startY = mQuestionValues[startX];
    let endY = mQuestionValues[endX];

    // İki mevcut nokta arasındaki her bir x değeri için y hesapla
    for (let x = startX + 1; x < endX; x++) {
        // Virgülden sonra 2 basamağa yuvarla
        const y = Number((startY + ((x - startX) / (endX - startX)) * (endY - startY)).toFixed(2));
        mQuestionValues[x] = y;  // Orijinal objeyi güncelle
    }
}

export const mLValues = {
    1: 30, 2: 34, 3: 38, 4: 42, 5: 45, 6: 49, 7: 53, 8: 56, 9: 60, 10: 64, 11: 68, 12: 71, 13: 75, 14: 79, 15: 83
}
export const mFValues = {
    1: 39, 2: 40, 3: 42, 4: 43, 5: 45, 6: 46, 7: 48, 8: 49, 9: 51, 10: 52, 11: 54, 12: 55, 13: 56, 14: 48, 15: 60, 16: 61, 17: 63, 18: 64, 19: 66, 20: 67, 21: 69, 22: 70, 23: 72, 24: 74, 25: 75, 26: 76, 27: 78, 28: 79, 29: 81, 30: 82, 31:84, 32: 85, 33: 87, 34: 88, 35: 90, 36:92, 37:93, 38:95, 39:97, 40:98, 41:99, 42:100, 43:101, 44:102, 45:103, 46:104, 47:106, 48:107, 49:109, 50:110, 51:111, 52:113, 53:114, 54:116, 55:117, 56:118, 58:119, 59:120, 60:120, 61:118, 62: 119, 63:120, 64:120
}

export const mKValues = {
    1: 22, 2: 25, 3: 26, 4: 29, 5: 31, 6: 33, 7: 36, 8: 37, 9: 39, 10: 42, 11: 45, 12: 47, 13: 48, 14: 50, 15: 52, 16: 54, 17: 57, 18: 59, 19: 61, 20: 63, 21: 65, 22: 67, 23: 69, 24: 72, 25: 75, 26: 77, 27: 79, 28: 80, 29: 82, 30: 85
}

export const mHsValues = {
    1: 20, 2: 22, 3: 26, 4: 28, 5: 29, 6: 32, 7: 34, 8: 37, 9: 39, 10: 42, 11: 45, 12: 46, 13: 49, 14: 51, 15: 54, 16: 56, 17: 59, 18: 61, 19: 63, 20: 66, 21: 68, 22: 71, 23: 73, 24: 76, 25: 78, 26: 80, 27: 83, 28: 85, 29: 88, 30: 90, 31: 93, 32:95, 33: 97, 34:99, 35:101, 36:103, 37:106, 38:108, 39:110, 40:112, 41:114, 42:117, 43:119, 44:120, 45:120, 46:120, 47:120, 48:120    
}

export const mDValues = {
    5: 19, 6: 21, 7: 22, 8: 25, 9: 27, 10: 29, 11: 31, 12: 33, 13: 35, 14: 37, 15: 39, 16: 41, 17: 43, 18: 45, 19: 48, 20: 50, 21:52, 22: 54, 23: 56, 24: 58, 25: 60, 26: 62, 27: 64, 28: 66, 29: 68, 30: 70, 31: 72, 32: 74, 33: 76, 34: 78, 35: 80, 36: 82, 37: 84, 38: 86, 39: 88, 40: 90, 41: 93, 42: 95, 43: 98, 44: 99, 45: 101, 46: 103, 47: 105, 48: 108, 49: 109, 50: 111 , 51:116, 52:118, 53:120, 54:120, 55:120, 56:120, 57:120, 58:120, 59:120, 60:120 
}

export const mHyValues = {
    5: 20, 6: 22, 7: 24, 8: 26, 9: 29, 10: 31, 11: 33, 12: 35, 13: 37, 14: 39, 15: 41, 16: 43, 17: 45, 18: 48, 19: 49, 20: 51, 21:53, 22: 56, 23: 58, 24: 60, 25: 62, 26: 64, 27: 66, 28: 68, 29: 70, 30: 72, 31: 74, 32: 76, 33: 78, 34: 81, 35: 83, 36: 85, 37: 87, 38: 89, 39: 91, 40: 93, 41: 95, 42: 97, 43: 99, 44: 101, 45: 103, 46: 106, 47: 108, 48: 110, 49: 112, 50: 114, 51:116, 52:118, 53:120, 54:120, 55:120, 56:120, 57:120, 58:120, 59:120, 60:120    
}

export const mPdValues = {
    10: 23, 11: 25, 12: 27, 13: 29, 14: 32, 15: 34, 16: 36, 17: 38, 18: 41, 19: 43, 20: 45, 21: 47, 22: 50, 23: 52, 24: 54, 25: 56, 26: 59, 27: 61, 28: 63, 29: 65, 30: 68, 31: 70, 32: 72, 33: 74, 34: 77, 35: 79, 36: 81, 37: 83, 38: 85, 39: 88, 40: 90, 41: 92, 42: 94, 43: 97, 44: 99, 45: 101, 46: 103, 47: 106, 48: 108, 49: 110, 50: 112, 51:114, 52:116, 53:119, 54:120, 55:120, 56:120, 57:120, 58:120, 59:120, 60:120, 61:120, 62:120
}

export const mMfValues = {
    14: 25, 15: 27, 16: 29, 17: 31, 18: 33, 19: 36, 20: 38, 21: 40, 22: 42, 23: 44, 24: 46, 25: 49, 26: 51, 27: 53, 28: 55, 29: 58, 30: 60, 31: 61, 32: 63, 33: 64, 34: 65, 35: 67, 36: 68, 37: 69, 38: 71, 39: 72, 40: 73, 41: 75, 42: 76, 43: 77, 44: 79, 45: 80, 46: 81, 47: 83, 48: 84, 49: 85, 50: 87, 51: 88, 52: 89, 53: 91, 54: 92, 55: 93, 56: 95, 57: 96, 58: 97, 59: 99, 60: 100
}

export const mPaValues = {
    1: 25, 2: 28, 3: 30, 4: 32, 5: 35, 6: 37, 7: 41, 8: 43, 9: 45, 10: 47, 11: 50, 12: 52, 13: 55, 14: 58, 15: 60, 16: 62, 17: 65, 18: 67, 19: 70, 20: 72, 21: 75, 22: 77, 23: 80, 24: 82, 25: 84, 26: 87, 27: 90, 28: 92, 29: 94, 30: 97, 31: 100, 32: 102, 33: 105, 34: 107, 35: 110, 36:113, 37:116, 38:120, 39:120, 40:120    
}

export const mPtValues = {
    10: 24, 11: 25, 12: 27, 13: 28, 14: 30, 15: 31, 16: 33, 17: 34, 18: 36, 19: 37, 20: 39, 21: 40, 22: 42, 23: 43, 24: 44, 25: 46, 26: 47, 27: 49, 28: 50, 29: 52, 30: 53, 31: 55, 32: 56, 33: 58, 34: 59, 35: 61, 36: 62, 37: 64, 38: 65, 39: 67,  40: 68, 41: 70, 42: 71, 43: 72, 44: 74, 45: 75, 46: 77, 47: 78, 48: 80, 49: 81, 50: 83, 51: 84, 52: 86, 53: 87, 54: 89, 55: 90, 56: 92, 57: 93, 58: 95, 59:97, 60:99, 61:101, 62:102, 63:103, 64:105, 65:107, 66:109, 67:111, 68:113, 69:115, 70:118, 71:120, 72:120, 73:120, 74:120, 75:120, 76:120, 77:120, 78:120
}

export const mScValues = {
    3: 21, 4: 22, 5: 23, 6: 24, 7: 25, 8: 26, 9: 27, 10: 29, 11: 30, 12: 31, 13: 32, 14: 33, 15: 34, 16: 35, 17: 36, 18: 37, 19: 38, 20: 39, 21: 41, 22: 42, 23: 43, 24: 44, 25: 45, 26: 46, 27: 47, 28: 48, 29: 49, 30: 50, 31: 51, 32: 52, 33: 54, 34: 55, 35: 56, 36: 57, 37: 58, 38: 59, 39: 60, 40: 61, 41: 62, 42: 63, 43: 64, 44: 66, 45: 67, 46: 68, 47: 69, 48: 70, 49: 71, 50: 72, 51: 73, 52: 74, 53: 75, 54: 76, 55: 78, 56: 79, 57: 80, 58: 81, 59: 82, 60: 83, 61: 84, 62: 85, 63: 86, 64: 87, 65: 88, 66: 90, 67: 91, 68: 92, 69: 93, 70: 94, 71: 95, 72: 96, 73: 97, 74: 98, 75: 99, 76: 100, 77: 101, 78: 103, 79:105, 80:107, 81:109, 82:110, 83:112, 84:114, 85:115, 86:116, 87:118, 88:120, 89:120, 90:120, 91:120, 92:120, 93:120, 94:120, 95:120, 96:120, 97:120, 98:120, 99:120, 100:120, 101:120, 102:120, 103:120, 104:120, 105:120, 106:120, 107:120, 108:120
}

export const mMaValues = {
    7: 22, 8: 24, 9: 26, 10: 28, 11: 31, 12: 33, 13: 35, 14: 37, 15: 39, 16: 42, 17: 44, 18: 46, 19: 48, 20: 50, 21: 53, 22: 55, 23: 57, 24: 59, 25: 61, 26: 63, 27: 66, 28: 68, 29: 70, 30: 72, 31: 74, 32: 77, 33: 79, 34: 81, 35: 83, 36: 85, 37: 88, 38: 90, 39: 92, 40: 94, 41: 96, 42: 98, 43: 101, 44: 103, 45: 105, 46: 107, 47:109, 48:110, 49:112, 50:114, 51:116, 52:117
}

export const mSiValues = {
    4: 23, 5: 24, 6: 25, 7: 26, 8: 28, 9: 29, 10: 30, 11: 31, 12: 33, 13: 34, 14: 35, 15: 37, 16: 38, 17: 39, 18: 40, 19: 42, 20: 43, 21: 44, 22: 45, 23: 47, 24: 48, 25: 49, 26: 50, 27: 52, 28: 53, 29: 54, 30: 55, 31: 57, 32: 58, 33: 59, 34: 61, 35: 62, 36: 63, 37: 64, 38: 66, 39: 67, 40: 68, 41: 69, 42: 71, 43: 72, 44: 73, 45: 74, 46: 76, 47: 77, 48: 78, 49: 80, 50: 81, 51: 82, 52: 83, 53: 85, 54: 86, 55: 87, 56: 88, 57: 90, 58: 91, 59: 92, 60: 93, 61: 95, 62: 96, 63: 97, 64: 98, 65: 100, 66: 101, 67: 102, 68: 104, 69: 105, 70: 106
}