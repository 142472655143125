const mQNumbers = {
    L: {
        questions: [
            ...[15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 195, 225, 255, 285].map(q => ({ question: q, condition: "Y" })),
        ]    
    },
    F: {
        questions: [
            ...[14, 23, 27, 31, 34, 35, 40, 42, 48, 49, 50, 53, 56, 66, 85, 121, 123, 139, 146, 151, 156, 168, 184, 197, 200, 202, 205, 206, 209, 210, 211, 215, 218, 227, 245, 246, 247, 252, 256, 269, 275, 286, 291, 293].map(q => ({ question: q, condition: "D" })),
            ...[17, 20, 54, 65, 75, 83, 112, 113, 115, 164, 169, 177, 185, 196, 199, 220, 257, 258, 272, 276].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    K: {
        questions: [
            ...[96].map(q => ({ question: q, condition: "D" })),
            ...[30, 39, 71, 89, 124, 129, 134, 138, 142, 148, 160, 170, 171, 180, 183, 217, 234, 267, 272, 296, 316, 322, 374, 383, 397, 398, 406, 461, 502].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    HS: {
        questions: [
            ...[23, 29, 43, 62, 72, 108, 14, 125, 161, 189, 273].map(q => ({ question: q, condition: "D" })),
            ...[2, 3, 7, 9, 18, 51, 55, 63, 68, 103, 130, 153, 155, 163, 175, 188, 190, 192, 230, 243, 274, 281].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    D: {
        questions: [
            ...[5, 13, 23, 32, 41, 43, 52, 67, 86, 104, 130, 138, 142, 158, 159, 182, 189, 193, 236, 259].map(q => ({ question: q, condition: "D" })),
            ...[2, 8, 9, 18, 30, 36, 39, 46, 51, 57, 58, 64, 80, 88, 89, 95, 98, 107, 122, 131, 145, 152, 153, 154, 155, 160, 178, 191, 207, 208, 233, 241, 242, 248, 263, 270, 271, 272, 285, 296].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    HY: {
        questions: [
            ...[10, 23, 32, 43, 44, 47, 76, 114, 179, 186, 189, 238, 253].map(q => ({ question: q, condition: "D" })),
            ...[2, 3, 6, 7, 8, 9, 12, 26, 30, 51, 55, 71, 89, 93, 103, 107, 109, 124, 128, 129, 136, 137, 141, 147, 153, 160, 162, 163, 170, 172, 174, 175, 180, 188, 190, 192, 201, 213, 230, 234, 243, 265, 267, 274, 279, 289, 292].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    PD: {
        questions: [
            ...[16, 21, 24, 32, 33, 35, 38, 42, 61, 67, 84, 94, 102, 106, 110, 118, 127, 215, 216, 224, 239, 244, 245, 284].map(q => ({ question: q, condition: "D" })),
            ...[8, 20, 37, 82, 91, 96, 107, 134, 137, 141, 155, 170, 171, 173, 180, 183, 201, 231, 235, 237, 248, 267, 287, 289, 294, 296].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    MF: {
        questions: [
            ...[4, 25, 69, 70, 74, 77, 78, 87, 92, 126, 132, 134, 140, 149, 179, 187, 203, 204, 217, 226, 231, 239, 261, 278, 282, 295, 297, 299].map(q => ({ question: q, condition: "D" })),
            ...[1, 19, 26, 28, 79, 80, 81, 89, 99, 112, 115, 116, 117, 120, 133, 144, 176, 198, 213, 214, 219, 221, 223, 229, 249, 254, 260, 262, 264, 280, 283, 300].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    PA: {
        questions: [
            ...[15, 16, 22, 24, 27, 35, 110, 121, 123, 127, 151, 157, 158, 202, 275, 284, 291, 293, 299, 305, 317, 338, 341, 364, 365].map(q => ({ question: q, condition: "D" })),
            ...[93, 107, 109, 111, 117, 124, 268, 281, 294, 313, 316, 319, 327, 347, 348].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    PT: {
        questions: [
            ...[10, 15, 22, 32, 41, 67, 76, 86, 94, 102, 106, 142, 159, 182, 189, 217, 238, 266, 301, 304, 305, 317, 321, 336, 337, 340, 342, 343, 344, 346, 349, 351, 352, 356, 357, 358, 359, 360, 361].map(q => ({ question: q, condition: "D" })),
            ...[3, 8, 36, 122, 152, 164, 178, 329, 353 ].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    SC: {
        questions: [
            ...[15, 16, 21, 22, 24, 32, 33, 35, 38, 40, 41, 47, 52, 76, 97, 104, 121, 156, 157, 159, 168, 179, 182, 194, 202, 210, 212, 238, 241, 251, 259, 266, 273, 282, 291, 297, 301, 303, 305, 307, 312, 320, 324, 325, 332, 334, 335, 339, 341, 345, 349, 350, 352, 354, 355, 356, 360, 363, 364 ].map(q => ({ question: q, condition: "D" })),
            ...[8, 17, 20, 37, 65, 103, 119, 177, 178, 187, 192, 196, 220, 276, 281, 306, 309, 322, 330  ].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    MA: {
        questions: [
            ...[11, 13, 21, 22, 59, 64, 73, 97, 100, 109, 127, 134, 143, 156, 157, 167, 181, 194, 212, 222, 226, 228, 232, 233, 238, 240, 250, 251, 263, 266, 268, 271, 277, 279, 298].map(q => ({ question: q, condition: "D" })),
            ...[101, 105, 111, 119, 120, 148, 166, 171, 180, 267, 289].map(q => ({ question: q, condition: "Y" })),
        ],
    },
    SI: {
        questions: [
            ...[32, 67, 82, 111, 117, 124, 138, 147, 171, 172, 180, 201, 236, 267, 278, 292, 304, 316, 321, 332, 336, 342, 357, 377, 383, 398, 411, 427, 436, 455, 473, 487, 549, 564].map(q => ({ question: q, condition: "D" })),
            ...[25, 33, 57, 91, 99, 119, 126, 143, 193, 208, 229, 231, 254, 262, 281, 296, 309, 353, 359, 371, 391, 400, 415, 440, 446, 449, 450, 451, 462, 469, 479, 481, 482, 505, 521, 547].map(q => ({ question: q, condition: "Y" })),
        ],
    },

};

export default mQNumbers;
