import React, { useState } from 'react';
import ManGraph from './components/ManGraph';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import "./App.css";
import ManGraph2 from './components/ManGraph2';

function App() {
  const [currentSelect, setCurrentSelect] = useState("Test Sonuçları Üzerinden");


  return (
    <div className="App flex flex-col min-h-screen justify-between">
      <Navbar
        currentSelect={currentSelect}
        setCurrentSelect={setCurrentSelect}
      />
      
      {currentSelect === "Ham Puan Üzerinden" && <ManGraph />}
      {currentSelect === "Test Sonuçları Üzerinden" && <ManGraph2 />}
      <Footer />
    </div>
  );
}

export default App;

